.views-content {
    overflow: hidden;

    .content-container {
        display: flex;
        flex-direction: column;
        padding-left: 20px;
        flex: 1;
    }

    .content-title {
        font-size: 16px;
        font-weight: bold;
        padding: 12px 0px 12px 0px;
    }

    .content-search {
        padding-bottom: 12px;

        .buttons {
            button {
                margin-right: 8px;
            }
        }
    }

    .content-buttons {
        padding-bottom: 12px;
    }
}

.urgent {
    .ant-drawer-body {
        padding: 12px 20px 20px 20px;
    }

    .ant-form-item {
        margin-bottom: 18px;
    }

    .ant-form > .ant-row {
        margin-bottom: 6px;
        padding-top: 6px;
    }

    .drawer-buttons {
        display: flex;
    }

    .drawer-box-title {
        font-size: 14px;
        font-weight: 500;
        color: rgba(0, 0, 0, 0.85);
        padding-bottom: 16px;
    }

    div.drawer-row-include {
        border: 1px dashed rgb(17, 131, 238);
        margin-bottom: 10px;
        padding-top: 10px;
        .ant-form-item {
            margin-bottom: 10px;
        }
    }
    div.drawer-row-space-between {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        border: 1px dashed rgb(17, 131, 238);
        align-items: center;
    
    }
    div.stone-row-include {
        border: 2px dashed rgb(171, 131, 238);
        margin-bottom: 10px;
        padding-top: 10px;
    }

   
}

.required {
    .ant-form-item-label {
        label::before {
            display: inline-block;
            margin-right: 4px;
            color: #ff4d4f;
            font-size: 14px;
            font-family: SimSun, sans-serif;
            line-height: 1;
            content: '*';
        }
    }
}
